import React, { useEffect } from "react";
import { updateLanguege } from "./slice";
import { useDispatch, useSelector } from "react-redux";

function Home() {
  const dispatch = useDispatch();

  const getLang = useSelector((state) => {
    return state.updateLang.locale;
  });

  useEffect(() => {
    dispatch(updateLanguege());
  }, [dispatch]);

  return (
    <div className="home-div">
      {getLang === "en" ? (
        <h1 className="home-div-header">
          <span className="astrolmeria-span">ALSTROEMERIA</span> <br />{" "}
          Development of devices to improve the energy efficiency of the
          operation of residential and commercial buildings through the
          introduction of smart devices based on temperature and pressure
          monitoring.
        </h1>
      ) : (
        <h1 className="home-div-header">
          <span className="astrolmeria-span">ALSTROEMERIA</span> <br />{" "}
          Երիտասարդ ընկերություն է, որը զբաղվում է բնակելի շենքերի և առևտրային
          տարածքների էներագաարդյունավետության բարձրացման սարքերի նախագծմամբ,
          ջերմաստիճանի և ճնշման մոնիտորինգի վրա հիմնված խելացի սարքերի ներդրման
          միջոցով:
        </h1>
      )}
    </div>
  );
}

export default Home;
