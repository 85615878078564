import React, { useEffect } from "react";
import { updateLanguege } from "../slice";
import { useDispatch, useSelector } from "react-redux";
import "../Styless/footer.css";

import LocationIcon from "@mui/icons-material/LocationCity";
import MobilePhone from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/EmailRounded";
export default function Footer() {
  const dispatch = useDispatch();

  const getLang = useSelector((state) => {
    return state.updateLang.locale;
  });

  useEffect(() => {
    dispatch(updateLanguege());
  }, [dispatch]);

  return (
    <div className="footer-dark">
      <footer>
        <div className="container">
          <div>
            <div className="row">
              <div className="col-sm-6 col-md-3 item">
                {getLang === "en" ? <h3>Address</h3> : <h3>Հասցե</h3>}

                <ul>
                  <li>
                    {getLang === "en" ? (
                      <a>
                        <LocationIcon /> 2407, Armenia Yerevan, Bagrevand 1/3
                      </a>
                    ) : (
                      <a>
                        <LocationIcon /> 2407, Ք․ Երևան, Բագրեվանդի 1/3
                      </a>
                    )}
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-md-3 item">
                {getLang === "en" ? <h3>Pheone</h3> : <h3>Հեռ․</h3>}
                <ul>
                  <li>
                    <a href="tel:+37455405089">
                      <MobilePhone /> +37455405089
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-md-3 item">
                {getLang === "en" ? <h3>Email</h3> : <h3>Էլ {""} փոստ</h3>}
                <ul>
                  <li>
                    <a href="mailto: llcalstroemeria@gmail.com">
                      <EmailIcon /> llcalstroemeria@gmail.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <p className="copyright">Alstroemeria © 2022</p>
        </div>
      </footer>
    </div>
  );
}
