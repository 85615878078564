import React, { useEffect } from "react";
import { updateLanguege } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import Card from "./Card";

function AboutUs() {
  const dispatch = useDispatch();

  const getLang = useSelector((state) => {
    return state.updateLang.locale;
  });

  useEffect(() => {
    dispatch(updateLanguege());
  }, [dispatch]);

  return (
    <div className="about-us-sec">
      {getLang === "en" ? (
        <div>
          <div className="card-class-sec">
            {/* <h1 className="about-us-hed">About company</h1> */}
            <div className="card-sec-div">
              <Card />
              <div className="info-sec">
                <p>Director – Hakob Harutyunyan</p>
                {/* <p> Հիմնադրաման տարեթիվ – 2022</p> */}
              </div>
            </div>
          </div>
          <div>
            <br />
            <h1>Business segment</h1>
            <ul>
              <li>
                Development of devices based on temperature and pressure
                monitoring for integration into power systems with the
                possibility of distributed monitoring/control/metering of energy
                consumption.
              </li>
            </ul>
          </div>
          <div>
            <h1>Partners</h1>
            <ul>
              <li>
                Elbat factory{" "}
                <a href="https://elbat.am/" target="_blank">
                  (www.elbat.am)
                </a>{" "}
                producing batteries. <br />
              </li>
              <li>
                Electron factory producing remote controls and screening
                scanners.
              </li>
            </ul>
          </div>
          <div>
            <h1>Main Customers</h1>
            <ul>
              <li>
                operating and managing organizations of buildings and private
                households, warehouses, greenhouses, incubators.
              </li>
            </ul>
          </div>

          <div>
            <h1>Main advantages</h1>
            <ul>
              <li>
                Integration into power systems with the possibility of
                distributed monitoring/control/metering of energy consumption.
              </li>
              <li>
                Availability of a reliable partner for the production of
                products.
              </li>
              <li>
                Availability of business contacts with operating and managing
                organizations.
              </li>
              <li> Availability of investors willing to promote products.</li>
            </ul>
          </div>
        </div>
      ) : (
        <div>
          <div className="card-class-sec">
            <h1 className="about-us-hed">Ըկերության մասին</h1>
            <div className="card-sec-div">
              <Card />
              <div className="info-sec">
                <p>Հիմնադիր տնօրեն – Հակոբ Հարությունյան</p>
                {/* <p> Հիմնադրաման տարեթիվ – 2022</p> */}
              </div>
            </div>
          </div>
          <div>
            <br />
            <h1>Հիմնական գործունեությունը</h1>
            <ul>
              <li>
                Էներգահամակարգերին ինտեգրվելու համար ջերմաստիճանի և ճնշման
                վերահսկման հիման վրա սարքերի մշակում՝ էներգիայի սպառման,
                հաշվառման բաշխված մոնիթորինգի (վերահսկման) հնարավորությամբ։
              </li>
            </ul>
          </div>
          <div>
            <h1>Ընկերության հիմնական առաքելությունը</h1>
            <ul>
              <li>
                Համապարփակ լուծումների մշակում շահագործվող շենքերի
                էներգաարդյունավետության բարելավեու ուղղությամբ:
              </li>
            </ul>
          </div>
          <div>
            <h1>Գործընկերներ</h1>
            <ul>
              <li>
                Էլբատ Կոնցեռնը{" "}
                <a href="https://elbat.am/" target="_blank">
                  (www.elbat.am)
                </a>{" "}
                դիվերսիֆիկացված գործարան է, որը զբաղվում է մարտկոցների
                արտադրությամբ, հեռակառավարման վահանակների համար։
              </li>
            </ul>
          </div>
          <div>
            <h1>Հիմնական հաճախորդներ</h1>
            <ul>
              <li>
                Բազմաբնակարան շենքերի շահագործող և կառավարող կազմակերպություններ
                և մասնավոր տնային տնտեսություններ, պահեստներ, ջերմոցներ,
                ինկուբատորներ:
              </li>
            </ul>
          </div>
          <div>
            <h1>Հիմնական մրցակցային առավելությունները</h1>
            <ul>
              <li>
                Ինտեգրում էներգահամակարգերին՝ էներգիայի սպառման, հաշվառման
                բաշխված մոնիթորինգի (վերահսկման) հնարավորությամբ։
              </li>
              <li>
                Դետալների արտադրության համար առկա են հուսալի գործընկերներ։
              </li>
              <li>
                Գործող և կառավարող կազմակերպությունների հետ գործարար կապերի
                առկայություն։
              </li>
              <li>
                {" "}
                Ներդրողների առկայություն, ովքեր պատրաստ են խթանել արտադրանքը։
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default AboutUs;
