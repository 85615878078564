import { combineReducers, createStore } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";

// import logger from "redux-logger";
// import thunk from "redux-thunk";
import { updateLanguegeReducer, defaultState } from "./slice";

const store = createStore(
  combineReducers({
    updateLang: updateLanguegeReducer,
  }),
  defaultState
);

export default store;
