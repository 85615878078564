import axios from "axios";
export const UPDATE_LANGUAGE_SUCSESS = "upadte_language_sucsess";
export const UPDATE_LANGUAGE_FAIL = "upadte_language_fail";

export const defaultState = {
  locale: "hy",
  texts: {},
};

export function updateLanguegeReducer(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_LANGUAGE_SUCSESS:
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return state;
  }
}

export const updateLanguege = (lang) => {
  return {
    type: UPDATE_LANGUAGE_SUCSESS,
    payload: lang,
  };
};
