import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

import cardImage from "./rsz_hakobnk.jpg";

export default function RecipeReviewCard() {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="350"
        image={cardImage}
        alt="green iguana"
      />
    </Card>
  );
}
