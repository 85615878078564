import React, { useCallback, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import logo from "./alstroemeria_adobe_express.svg";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import { updateLanguege } from "./slice";
import { useDispatch } from "react-redux";

export default function Header() {
  const [value, setValue] = useState("hy");

  const dispatch = useDispatch();

  const handleSelect = useCallback((e) => {
    setValue(e);
    dispatch(updateLanguege(e));
  }, []);

  return (
    <div>
      <Navbar bg="" variant="dark">
        <>
          <Navbar.Brand href="/">
            <img
              alt=""
              src={logo}
              width="110"
              height="70"
              className="d-inline-block align-top"
            />{" "}
            Alstroemeria
          </Navbar.Brand>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  // variant="dark"
                  className="language-btn"
                >
                  <LanguageRoundedIcon /> {}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="hy">Hy</Dropdown.Item>
                  <Dropdown.Divider />

                  <Dropdown.Item eventKey="hy">HY</Dropdown.Item>
                  <Dropdown.Item eventKey="en">En</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </>
      </Navbar>
    </div>
  );
}
